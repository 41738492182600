import React from 'react';
import AVCtrlBase from '../page/AVCtrlBase';
import AVButton from '../components/ui/av/AVButton';
import AVInputCombo from '../components/ui/av/controllers/AVInputCombo';
import AVFaderCombo from '../components/ui/av/controllers/AVFaderCombo';
import AVVuMeter from '../components/ui/av/AVVuMeter';
import AVFader from '../components/ui/av/AVFader';
import { useParams } from 'react-router-dom';

import '../components/ui/av/avstyle.scss';
import AVPiPSourceSelector from '../components/ui/av/controllers/AVPiPSourceSelector';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class AVCtrlAudience extends AVCtrlBase {
  
  render() {
    if(this.state.realstatus == null)
      return <div>vmix not ready</div>;
    const guests = Array.from(new Array(3).keys()).map((i)=>this.get_input(`Guest ${i+1}`));
    const PCs = Array.from(new Array(3).keys()).map((i)=>this.get_input(`PC ${i+1}`));
    const PiPs = Array.from(new Array(4).keys()).map((i)=>this.get_input(`PiP Audience ${i+1}`));
    
    return <div style={{backgroundColor:"#333",height:"fit-content"}}>
      <div className="av hwshell maincontainer">

<div id="triptic" className="av hbox">
  <div id="triptic-left" className="av vbox" >
    <div id="otherinputs" className="av panel vbox">
      <div  id="guests" className="av hbox">
        {guests.map((guest,i)=>(
          <AVInputCombo
            key={"guest"+guest+i}
            input_data = {guest}
            label={`Guest ${i+1}`}
            suspend_waiting={this.state.selecting_pip_source}
            suspend_waiting_color={this.is_active_pip_source("audience",guest.key)?"blue":"white"}
            onClick_suspend_waiting={(e)=>this.set_pip_source("audience",guest.key)}
            onClick={(e)=>this.api_call("/real/destinations/audience",{source:guest.key})}
            active={(this.state.realstatus.avctrl.destinations.audience.source === guest.number)?"active":""}
            active_color={this.state.realstatus.avctrl.destinations.audience.source===guest.number?"red":"green"}
          />
        ))}
      </div>
      <div  id="pcs" className="av hbox">
          {PCs.map((pc,i)=>(
            <AVInputCombo
              key={"pc"+pc+i}
              input_data = {pc}
              label={`PC ${i+1}`}
              suspend_waiting={this.state.selecting_pip_source}
              suspend_waiting_color={this.is_active_pip_source("audience",pc.key)?"blue":"white"}
              onClick_suspend_waiting={(e)=>this.set_pip_source("audience",pc.key)}
              onClick={(e)=>this.api_call("/real/destinations/audience",{source:pc.key})}
              // onshokitctrl_url={this.state.onshokitctrl_url}
              input={`PC ${i+1}`}
              active={(this.state.realstatus.avctrl.destinations.audience.source === pc.number)?"active":""}
              active_color={this.state.realstatus.avctrl.destinations.audience.source===pc.number?"red":"green"}
            />
          ))}
      </div>
      <div  id="pips" className="av hbox">
          {PiPs.map((pip,i)=>(
            <AVInputCombo
              key={"pip"+pip+i}
              input_data = {pip}
              label={`PiP ${i+1}`}
              onClick={(e)=>{
                this.setState({selectedPiP:i+1}); 
                this.api_call("/real/destinations/audience",{source:pip.key});
              }}
              input={`PiP ${i+1}`}
              active={(this.state.realstatus.avctrl.destinations.audience.source === pip.number)?"active":""}
              active_color={this.state.realstatus.avctrl.destinations.audience.source===pip.number?"red":"green"}
            />
          ))}
        </div>
    </div>
  </div>
  <div id="triptic-middle" className="av vbox panel" >
  <div id="tabs" className="av hbox" style={{width:75*7,height:75*5}}>  
    <div className="av vbox">  
    <div className="av hbox">  
    
    <button 
      className={"av tabbtn " + (this.state.active_panel === "mixer"?"active":"")} 
      onClick={()=>this.setState({active_panel:"mixer"})}> mixer</button>
    <button 
      className={"av tabbtn " + (this.state.active_panel === "pip"?"active":"")} 
      onClick={()=>this.setState({active_panel:"pip"})}> PiP</button>
    </div>
    <div className="av panel" style={{width: 485, height:355, marginTop:0}}>
      {this.state.active_panel === "mixer" && (
      <div id="mixer" className="av hbox" style={{width:75*7,height:75*5.5}}>  
        <AVFaderCombo label="Micros" 
          audio_destination="audience"
          audio_source="micros"
          muted={this.state.realstatus.avctrl.audio_destinations.audience.micros.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Média" 
          audio_destination="audience"
          audio_source="medias"
          muted={this.state.realstatus.avctrl.audio_destinations.audience.medias.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 1" 
          audio_destination="audience"
          audio_source="guest1"
          muted={this.state.realstatus.avctrl.audio_destinations.audience.guest1.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 2" 
          audio_destination="audience"
          audio_source="guest2"
          muted={this.state.realstatus.avctrl.audio_destinations.audience.guest2.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 3" 
          audio_destination="audience"
          audio_source="guest3"
          muted={this.state.realstatus.avctrl.audio_destinations.audience.guest3.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="PC 1" 
          onChange={(v)=>this.api_call("/real/audio_destinations/audience",{pc1:{value:v}})}
          audio_destination="audience"
          audio_source="pc1"
          muted={this.state.realstatus.avctrl.audio_destinations.audience.pc1.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />
        <AVFaderCombo label="PC 2" 
          onChange={(v)=>this.api_call("/real/audio_destinations/audience",{pc2:{value:v}})}
          audio_destination="audience"
          audio_source="pc2"
          muted={this.state.realstatus.avctrl.audio_destinations.audience.pc2.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />
        <AVFaderCombo label="PC 3" 
          onChange={(v)=>this.api_call("/real/audio_destinations/audience",{pc3:{value:v}})}
          audio_destination="audience"
          audio_source="pc3"
          muted={this.state.realstatus.avctrl.audio_destinations.audience.pc3.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />

      </div>
        )}
        {this.state.active_panel == "pip" &&(
          <div>
            <AVPiPSourceSelector
              pip_destinations={this.state.realstatus.avctrl.pips.audience}
              selection_active={this.state.selecting_pip_source}
              selectedPiP={this.state.selectedPiP}
              on_selection_start={(selectedPiP,selectedOverlay)=>{
                this.setState({
                  selecting_pip_source:true,
                  selectedPiP:selectedPiP,
                  selectedOverlay:selectedOverlay
                })
              }}
            ></AVPiPSourceSelector>
            </div>
        )}

  </div>
    <div>
    </div>
    </div>
  </div>
    
  </div>
</div>

  </div>

    </div>;
  }
}

export default withParams(AVCtrlAudience);