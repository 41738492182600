import React, { useState, useEffect } from 'react';
import { Button, Container, Select, TextField, Typography, MenuItem } from '@mui/material';

const FactureForm = ({ captationId }) => {
  const [facture, setFacture] = useState({
    identiteEntrepreneur: '',
    raisonSociale: '',
    adresseFacturation: '',
    numeroSIREN: '',
    numeroSIRET: '',
    numeroRM: '',
    formeJuridique: '',
    greffeImmatriculation: '',
    identiteClient: '',
    adresseClient: '',
    raisonSocialeClient: '',
    numeroTVA: '',
    dateEmission: '',
    numeroFacture: '',
    dateVentePrestation: '',
    designationPrestation: '',
    prixUnitaireHT: '',
    tauxTVA: '',
    montantTVA: '',
    reductions: '',
    montantTotal: '',
    captationId: captationId, // Utilisez la prop captationId ici
  });
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchFacture();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/b/get-users', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        setError('Erreur lors de la récupération des utilisateurs.');
      }
    } catch (error) {
      setError('Erreur lors de la connexion au serveur.');
    }
  };

  const fetchFacture = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log(captationId);
      const response = await fetch(`/b/get-facture-by-captation/${captationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setFacture(prevFacture => ({
          ...prevFacture,
          ...data
        }));
      } else {
        console.error('Erreur lors de la récupération de la facture.');
      }
    } catch (error) {
      console.error('Erreur lors de la connexion au serveur.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userInfo = localStorage.getItem('userInfo');
    const token = localStorage.getItem('token');

    if (!userInfo) {
      alert('Vous devez être connecté pour enregistrer une facture.');
      return;
    }
    const user = JSON.parse(userInfo);
    const userId = facture.identiteClient;
    const newFacture = { ...facture, identiteClient: userId, captationId: captationId }; // Ajoutez captationId ici

    try {
      const response = await fetch(`/b/add-facture/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(newFacture),
      });
      if (response.ok) {
        alert('Facture enregistrée avec succès.');
      } else {
        alert('Erreur lors de l\'enregistrement de la facture.');
      }
    } catch (error) {
      alert('Erreur lors de la connexion au serveur.');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFacture(prevFacture => ({
      ...prevFacture,
      [name]: value
    }));
  };

  return (
    <Container component="main">
      <Typography variant="h4">Facture</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          select
          label="Client"
          value={facture.identiteClient || ''}
          name="identiteClient"
          onChange={handleChange}
          margin="normal"
          fullWidth
        >
          {users.map((user, index) => (
            <MenuItem key={index} value={user._id}>
              {user.name} - {user.email}
            </MenuItem>
          ))}
        </TextField>

        {/* Informations sur l'entrepreneur individuel */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Qui vous êtes"
          name="identiteEntrepreneur"
          value={facture.identiteEntrepreneur}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Raison sociale de l'entreprise principale"
          name="raisonSociale"
          value={facture.raisonSociale}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Adresse de facturation ou du siège social"
          name="adresseFacturation"
          value={facture.adresseFacturation}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Numéro SIREN"
          name="numeroSIREN"
          value={facture.numeroSIREN}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Numéro SIRET"
          name="numeroSIRET"
          value={facture.numeroSIRET}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Numéro RM (Répertoire des Métiers)"
          name="numeroRM"
          value={facture.numeroRM}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Forme juridique"
          name="formeJuridique"
          value={facture.formeJuridique}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Greffe d'immatriculation"
          name="greffeImmatriculation"
          value={facture.greffeImmatriculation}
          onChange={handleChange}
        />
        {/* Informations sur le client */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Identité du client"
          name="identiteClient"
          value={facture.identiteClient}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Raison sociale du client"
          name="raisonSocialeClient"
          value={facture.raisonSocialeClient}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Adresse du client"
          name="adresseClient"
          value={facture.adresseClient}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Numéro de TVA du client"
          name="numeroTVA"
          value={facture.numeroTVA}
          onChange={handleChange}
        />
        {/* Informations sur la facture */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Date d'émission"
          type="date"
          name="dateEmission"
          value={facture.dateEmission}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Numéro de facture"
          name="numeroFacture"
          value={facture.numeroFacture}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Date de vente ou prestation"
          type="date"
          name="dateVentePrestation"
          value={facture.dateVentePrestation}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Désignation de la prestation"
          name="designationPrestation"
          value={facture.designationPrestation}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Prix unitaire hors TVA"
          name="prixUnitaireHT"
          value={facture.prixUnitaireHT}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Taux de TVA applicable"
          name="tauxTVA"
          value={facture.tauxTVA}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Montant de la TVA"
          name="montantTVA"
          value={facture.montantTVA}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Réductions"
          name="reductions"
          value={facture.reductions}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Montant total à payer"
          name="montantTotal"
          value={facture.montantTotal}
          onChange={handleChange}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Valider
        </Button>
      </form>
    </Container>
  );
};

export default FactureForm;
