import React from 'react';
import AVButton from '../components/ui/av/AVButton';
import AVInputCombo from '../components/ui/av/controllers/AVInputCombo';
import AVFaderCombo from '../components/ui/av/controllers/AVFaderCombo';
import AVVuMeter from '../components/ui/av/AVVuMeter';
import AVFader from '../components/ui/av/AVFader';

const INPUT_DUMMY = {
  preview:false,
  active:false,
  meterF1:0,
  meterF2:0,
  muted:true,
  dummy:true,
}

const styles = {
  panel:{
    backgroundColor:"#333",
    height:"fit-content"
  },
  hbox:{
    display:"flex",
    flexDirection:"row",
    justifyContent:""
  },
  vbox:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start"
  },
  root: {
    height: '100vh',
    // backgroundColor: '#444',
    backgroundColor:"gray",
    display: 'flex',
    flexWrap:"wrap", width:"100%", 
  },
  absolute: {
    position: 'absolute',
    padding:"0.2em"
  },
  button: {
    position: 'absolute',
    padding:"0.2em",
    "&:hover": {
      background: "#efefef"
    },
  },
  topButton: {
    top: '20px',
    left: '20px',
    width: '120px',
    height: '50px',
  },
};

class AVCtrlBase extends React.Component {
  static defaultProps = {
    destination_name:"master"
  }

  state = {
    error: null,
    onshokitctrl_url:"",
    vmixstatus:null,
    realstatus:null,
    sync_guests_from_pa:false,
    sync_guests_from_pgm:false,
    sync_pcs_from_pa:false,
    sync_pcs_from_pgm:false,
  };

  async componentDidMount(){
    const interval = async () => {
        const query = `${this.state.onshokitctrl_url}/real`;
        const response = await fetch(query);
        if(response.ok){
          const data = await response.json();
          this.setState({
            vmixstatus:data.vmix,
            realstatus:data
          })
          setTimeout(interval, 300);
      }
      else{
        console.log("error getting "+ query)
      }
    }
    interval()
  }
  
  
  is_active_pip_source(pip_destination, key){
    if(this.state.selecting_pip_source){
      const input = this.get_input(key);
      for(const source of this.state.realstatus.avctrl.pips[pip_destination][this.state.selectedPiP].sources){
        const sourceinput = this.get_input(source.key);
        if(input.key === sourceinput.key)
          return true;
      }
    }
    return false;
  }

  async api_call(path, data){
    const query = `${this.state.onshokitctrl_url}${path}`;
    console.log(query);
    await fetch(query,{
      method: "POST",
      headers: {"Content-Type": "application/json"},
     body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  }
  
  set_pip_source(pip_destination, key){
    console.log("set", key, "to overlay", this.state.selectedOverlay, "of pip", this.state.selectedPiP);
    this.api_call(`/real/pips/${pip_destination}/${this.state.selectedPiP}/sources/${this.state.selectedOverlay}`,
      {source:key}
    )
    this.setState({
      selecting_pip_source:false
    })
  }

  get_input(selector){
    return this.state.vmixstatus.inputs.input.find(i=>i.number === selector) ||
    this.state.vmixstatus.inputs.input.find(i=>i.shortTitle === selector) ||
    this.state.vmixstatus.inputs.input.find(i=>i.title === selector) ||
    this.state.vmixstatus.inputs.input.find(i=>i.key === selector) || 
    {...INPUT_DUMMY};
  }
  
  slider_change(event) {
    console.log("change", event.target.value);
  }

  handleMouseDown(event) {
    let dragging = true;
    console.log("down", event.target.value);

    const handleMouseMove = (event) => {
      if (dragging) {
        console.log("dragging", event.target.value);
      }
    };

    const handleMouseUp = () => {
      dragging = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  }

  render() {
    if(this.state.vmixstatus == null)
      return <div>vmix not ready</div>;

    const guests = Array.from(new Array(3).keys()).map((i)=>this.get_input(`Guest ${i+1}`));
    // console.log(guests)
    const PCs = Array.from(new Array(3).keys()).map((i)=>this.get_input(`PC ${i+1}`));

    console.log(this.state.realstatus.avctrl.destinations)

    return <div style={{backgroundColor:"#333",height:"fit-content"}}>
      <div className="av hwshell maincontainer">
  <div id="triptic-left" className="av vbox" >
    <span className="av panel vbox">
      <div   className="av hbox">
              {PCs.map((pc,i)=>(
                <AVInputCombo
                  input_data = {pc}
                  label={`PC ${i+1}`}
                  input={`PC ${i+1}`}
                  suspend_waiting={this.state.selecting_pip_source}
                  // suspend_waiting_color={this.is_active_pip_source(pc.key)?"blue":"white"}
                  // onClick_suspend_waiting={(e)=>this.set_pip_source(pc.key)}
                  onClick={(e)=>this.api_call("/real/destinations/"+this.props.destination_name,{source:pc.key})}
                  active={this.state.realstatus.avctrl.destinations[this.props.destination_name].source === pc.number || this.state.realstatus.avctrl.destinations[this.props.destination_name].source === pc.number  }
                  active_color={this.state.realstatus.avctrl.destinations[this.props.destination_name].source===pc.number?"red":"green"}
                />
              ))}
            </div>
          </span>
    <span className="av panel vbox">
      <div  id="cameras-cut" className="av hbox">
              {guests.map((guest,i)=>(
                <AVInputCombo
                  input_data = {guest}
                  label={`Guest ${i+1}`}
                  input={`Guest ${i+1}`}
                  suspend_waiting={this.state.selecting_pip_source}
                  // suspend_waiting_color={this.is_active_pip_source(pc.key)?"blue":"white"}
                  // onClick_suspend_waiting={(e)=>this.set_pip_source(pc.key)}
                  onClick={(e)=>this.api_call("/real/destinations/"+this.props.destination_name,{source:guest.key})}
                  active={this.state.realstatus.avctrl.destinations[this.props.destination_name].source === guest.number }
                  active_color={this.state.realstatus.avctrl.destinations[this.props.destination_name].source===guest.number?"red":"green"}
                />
              ))}
            </div>
          </span>
          
    <span  className="av panel vbox">
      <div  id="cameras-cut" className="av hbox">
              {this.props.children}
            </div>
          </span>
        </div>
        
    <div  className="av panel vbox">
      {(
      <div id="mixer" className="av hbox" style={{width:75*7,height:75*5.5}}>    
        <AVFaderCombo label="Micros" 
          audio_destination={this.props.destination_name}
          audio_source="micros"
          muted={this.state.realstatus.avctrl.audio_destinations[this.props.destination_name].micros.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Média" 
          audio_destination={this.props.destination_name}
          audio_source="medias"
          muted={this.state.realstatus.avctrl.audio_destinations[this.props.destination_name].medias.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 1" 
          audio_destination={this.props.destination_name}
          audio_source="guest1"
          muted={this.state.realstatus.avctrl.audio_destinations[this.props.destination_name].guest1.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 2" 
          audio_destination={this.props.destination_name}
          audio_source="guest2"
          muted={this.state.realstatus.avctrl.audio_destinations[this.props.destination_name].guest2.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 3" 
          audio_destination={this.props.destination_name}
          audio_source="guest3"
          muted={this.state.realstatus.avctrl.audio_destinations[this.props.destination_name].guest3.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="PC 1" 
          onChange={(v)=>this.api_call("/real/audio_destinations/"+ this.props.destination_name,{pc1:{value:v}})}
          audio_destination={this.props.destination_name}
          audio_source="pc1"
          muted={this.state.realstatus.avctrl.audio_destinations[this.props.destination_name].pc1.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />
        <AVFaderCombo label="PC 2" 
          onChange={(v)=>this.api_call("/real/audio_destinations"+this.props.destination_name,{pc2:{value:v}})}
          audio_destination={this.props.destination_name}
          audio_source="pc2"
          muted={this.state.realstatus.avctrl.audio_destinations[this.props.destination_name].pc2.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />
        <AVFaderCombo label="PC 3" 
          onChange={(v)=>this.api_call("/real/audio_destinations"+this.props.destination_name,{pc3:{value:v}})}
          audio_destination={this.props.destination_name}
          audio_source="pc3"
          muted={this.state.realstatus.avctrl.audio_destinations[this.props.destination_name].pc3.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />

      </div>
        )}
            
        {/* TODO: global master meter + snapshots 
        <span style={styles.panel} >
          <div style={{display:"flex"}}>
            <AVVuMeter
                F1={this.state.vmixstatus.audio.master.meterF1}
                F2={this.state.vmixstatus.audio.master.meterF2} />
            <img  alt="snapshot" style={{height:65}}
              // src={`${this.state.onshokitctrl_url}/real/inputs/input/1/snapshot`}
            ></img> 
          </div>
        </span>
         */}

        </div>
        </div>
        </div>;
  }
}

export default AVCtrlBase;