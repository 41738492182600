import React from 'react';
import AVCtrlBase from './AVCtrlBase';

class AVCtrlGuests extends React.Component {
  render() {
    return <AVCtrlBase
      destination_name="guests"
    ></AVCtrlBase>
  }
}

export default AVCtrlGuests;