import React, { useState , useEffect } from 'react';
import {Avatar , Paper, Typography, TextField, Button, Container , List , ListItem , ListItemText, ListItemAvatar} from '@mui/material';
import { useParams } from 'react-router-dom';
import Alert from '../components/alert';
const token = localStorage.getItem('token');

function CaptationDetails() {
    const {captationId} = useParams();
    const [showAlert, setShowAlert] = useState(false); // État pour afficher ou masquer l'alerte
    const [error, setError] = useState('');
    const [guests, setGuests] = useState([]);

    const [newGuest, setNewGuest] = useState({
        nom_invite: '',
        prenom_invite: '',
        email_invite: '',
        info_invite: '',
        fonction_invite: '',
        image_invite: null
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewGuest({
            ...newGuest,
            [name]: value
        });
    };
    const fetchGuests = async () => {
        try {
            const response = await fetch(`/b/get-guests/${captationId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des invités');
            }
            const data = await response.json();
            setGuests(data);
        } catch (error) {
            setError(error.message || "Erreur de récupération des invités");
            setShowAlert(true);
        }
    };
    useEffect(() => {
       

        fetchGuests();
    }, [captationId]);
    const handleImageUpload = (event) => {
        setNewGuest({
            ...newGuest,
            image_invite: event.target.files[0]
        });
    };
   
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('nom_invite', newGuest.nom_invite);
        formData.append('prenom_invite', newGuest.prenom_invite);
        formData.append('email_invite', newGuest.email_invite);
        formData.append('info_invite', newGuest.info_invite);
        formData.append('fonction_invite', newGuest.fonction_invite);
        formData.append('image_invite', newGuest.image_invite);
        formData.append('captation_id', captationId);
        try {
            const response = await fetch('/b/add-guest', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            });
            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout de l\'invité');
            }
            const data = await response.json();
            fetchGuests();
        } catch (error) {
            setError(error.message || "Erreur d'ajout de l\'invité");
            setShowAlert(true); 
        }
    };
    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h4" align="center">Création d'un invité</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Nom"
                        name="nom_invite"
                        value={newGuest.nom_invite}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Prénom"
                        name="prenom_invite"
                        value={newGuest.prenom_invite}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email_invite"
                        value={newGuest.email_invite}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Information supplémentaire"
                        name="info_invite"
                        value={newGuest.info_invite}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Fonction"
                        name="fonction_invite"
                        value={newGuest.fonction_invite}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                        <input
                        type="file"
                        name="image_invite"
                        onChange={handleImageUpload}
                        style={{ margin: '20px 0' }}
                        accept="image/*"  
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Ajouter l'invité
                    </Button>
                </form>
            </Paper>
            {/* Affichage de l'alerte en cas d'erreur */}
            {showAlert && <Alert severity="error" message={error} onClose={() => setShowAlert(false)} />}
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h4" align="center">Liste des invités</Typography>
                <List>
                    {guests.map((guest, index) => (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar src={`data:image/jpeg;base64,${guest.photo}`} alt={`${guest.nom} ${guest.prenom}`} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${guest.nom} ${guest.prenom}`}
                                secondary={guest.email}
                            />
                            <ListItemText
                                primary={ guest.fonction}
                                secondary={guest.info}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Container>
    );
}

export default CaptationDetails;