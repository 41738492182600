import React from 'react';
import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Typography, Button } from '@mui/material';
import { theme } from '../theme'; // Assurez-vous que le chemin d'accès est correct
import WebFont from 'webfontloader';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import objectSupport from 'dayjs/plugin/objectSupport';
import dayjs from 'dayjs';
dayjs.extend(objectSupport);



class OnshoPageContainer extends React.Component {  

  static defaultProps ={
    open:true
  }
  constructor(props) {
    super(props);
  }
  
  componentWillMount = () => {
    WebFont.load({
      custom: {
        families: ['Switzer', 'Archiv-Onsho'],
        urls: ['/fonts/onsho-fonts.css']
      }
    });
  }

  
  render() {
      return <ThemeProvider theme={theme}>
          <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* <Button  variant="contained" color="primary" onClick={()=>this.setState({open:true})}>Open drawer</Button> */}
          <Drawer variant="permanent" style={{visibility:this.props.open?"inherit":"collapse"}}>
            <Box sx={{ width: 250 }} role="presentation">
                
                {/* <ListItemButton href="#" onClick={()=>this.setState({open:false})}>
                    <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                    <ListItemText primary="hide"></ListItemText>
                  </ListItemButton> */}
              <List 
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    SaaS
                  </ListSubheader>
                }>
                <ListItemButton href="/home">
                    <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                    <ListItemText primary="Accueil"></ListItemText>
                  </ListItemButton>
                <ListItemButton href="/dashboard">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="Tableau de bord"></ListItemText>
                </ListItemButton>
                <ListItemButton href="/my-recordings">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="Mes captations"></ListItemText>
                </ListItemButton>
                <ListItemButton href="/GestionUser">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="Mon profil"></ListItemText>
                </ListItemButton>
                <ListItemButton href="/login">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="Connexion"></ListItemText>
                </ListItemButton>
                <ListItemButton href="/register">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="Inscription"></ListItemText>
                </ListItemButton>
                <ListItemButton href="/Verification">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="Vérification e-mail"></ListItemText>
                </ListItemButton>
                <ListItemButton href="/PasswordOublie">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="Mot de passe oublié"></ListItemText>
                </ListItemButton>
                <ListItemButton href="/reset-password-validation/:token">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="Validation du password"></ListItemText>
                </ListItemButton>

              </List>
              <Divider />
              <List 
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    AV control
                  </ListSubheader>
                }>
                  <ListItemButton href="/vmixctrl">
                      <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                      <ListItemText primary="vmixctrl"></ListItemText>
                  </ListItemButton>
                  <ListItemButton href="/kitdashboard">
                      <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                      <ListItemText primary="Kit Dashboard"></ListItemText>
                  </ListItemButton>
                  <ListItemButton href="/director">
                    <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                    <ListItemText primary="director"></ListItemText>
                  </ListItemButton>
                  <ListItemButton href="/operator">
                    <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                    <ListItemText primary="Operator (Audience)"></ListItemText>
                  </ListItemButton>
              </List>
              <Divider />
              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Misc.
                  </ListSubheader>
                }>
                <ListItemButton href="/themetemplate">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="UI Onsho"></ListItemText>
                </ListItemButton>
                <ListItemButton href="/admin">
                  <ListItemIcon><LabelImportantIcon/></ListItemIcon>
                  <ListItemText primary="admin"></ListItemText>
                </ListItemButton>
              </List>
              <Divider />
            </Box>
          </Drawer>
          <Container component="main" sx={{ width: '100%' }} >
            {this.props.children}
          </Container>
          {/* <Box><h1>footer</h1><p>{process.env.REACT_APP_VERSION}</p></Box> */}
          </LocalizationProvider>

        </ThemeProvider>;
    }
  }
  

export default OnshoPageContainer;
