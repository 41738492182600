import React, { useState, useEffect } from "react";
import {
  Avatar,
  Paper,
  Typography,
  TextField,
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Alert from "../components/alert";

const token = localStorage.getItem("token");

const CaptationDetails = () => {
  const { captationId } = useParams(); // Utilisation de useParams pour obtenir captationId
  const [guests, setGuests] = useState([]); // État des invités
  const [selectedGuestId, setSelectedGuestId] = useState(null);

  const [newGuest, setNewGuest] = useState({
    nom_invite: "",
    prenom_invite: "",
    email_invite: "",
    info_invite: "",
    fonction_invite: "",
    image_invite: null,
  });
  const [editguest, setEditGuest] = useState({
    nom_invite: "",
    prenom_invite: "",
    email_invite: "",
    info_invite: "",
    fonction_invite: "",
    image_invite: null,
  });
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const DeleteGuest = async (id) => {
    try {
      const response = await fetch(`/b/delete-guest/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la suppression de l'invité");
      }
      await fetchGuests(); // Actualiser la liste des invités après la suppression
    } catch (error) {
      setError(error.message || "Erreur de suppression de l'invité");
      setShowAlert(true);
    }
  };
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = (guest) => {
    setEditGuest({
        nom_invite: guest.nom,
        prenom_invite: guest.prenom,
        email_invite: guest.email,
        info_invite: guest.info_supplementaire,
        fonction_invite: guest.fonction,
        image_invite: guest.photo || null, // Si tu veux gérer la photo déjà existante
      });
    setSelectedGuestId(guest._id);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const fetchGuests = async () => {
    try {
      const response = await fetch(`/b/get-guests/${captationId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des invités");
      }
      const data = await response.json();
      setGuests(data); // Mise à jour des invités avec les données récupérées
    } catch (error) {
      setError(error.message || "Erreur de récupération des invités");
      setShowAlert(true);
    }
  };
  useEffect(() => {
    fetchGuests();
  }, [captationId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewGuest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleInputChangeEdit = (event) => {
    const { name, value } = event.target;
    setEditGuest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = (event) => {
    setNewGuest((prevState) => ({
      ...prevState,
      image_invite: event.target.files[0],
    }));
  };
  const modifyGuest = async (id) => {
    console.log(editguest);
    try {
      const response = await fetch(`/b/update-guest/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editguest),
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la modification de l'invité");
      }
      await fetchGuests(); 
      setOpenDialog(false);

    } catch (error) {
      setError(error.message || "Erreur de modification de l'invité");
      setShowAlert(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("nom_invite", newGuest.nom_invite);
    formData.append("prenom_invite", newGuest.prenom_invite);
    formData.append("email_invite", newGuest.email_invite);
    formData.append("info_invite", newGuest.info_invite);
    formData.append("fonction_invite", newGuest.fonction_invite);
    formData.append("image_invite", newGuest.image_invite);
    formData.append("captation_id", captationId);
    try {
      const response = await fetch("/b/add-guest", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Erreur lors de l'ajout de l'invité");
      }
      await fetchGuests(); // Actualiser la liste des invités après l'ajout
    } catch (error) {
      setError(error.message || "Erreur d'ajout de l'invité");
      setShowAlert(true);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Typography variant="h4" align="center">
          Création d'un invité
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nom"
            name="nom_invite"
            value={newGuest.nom_invite}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Prénom"
            name="prenom_invite"
            value={newGuest.prenom_invite}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email_invite"
            value={newGuest.email_invite}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Information supplémentaire"
            name="info_invite"
            value={newGuest.info_invite}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fonction"
            name="fonction_invite"
            value={newGuest.fonction_invite}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <input
            type="file"
            name="image_invite"
            onChange={handleImageUpload}
            style={{ margin: "20px 0" }}
            accept="image/*"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Ajouter l'invité
          </Button>
        </form>
      </Paper>

      {showAlert && (
        <Alert
          severity="error"
          message={error}
          onClose={() => setShowAlert(false)}
        />
      )}

      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px", width: '100%'}}>
        <Typography variant="h4" align="center">
          Liste des invités
        </Typography>
        <List style={{ width: '100%' }}>
          {guests.map((guest, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar
                  src={`data:image/jpeg;base64,${guest.photo}`}
                  alt={`${guest.nom} ${guest.prenom}`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${guest.nom} ${guest.prenom}`}
                secondary={guest.email}
              />
              <ListItemText
                primary={guest.fonction}
                secondary={guest.info_supplementaire}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => DeleteGuest(guest._id)}
                
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClickOpen(guest)}
                
              >
                Modifier
              </Button>
            </ListItem>
          ))}
        </List>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Modifier l'invité</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            name="nom_invite"
            value={editguest.nom_invite}
            onChange={handleInputChangeEdit}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Prénom"
            name="prenom_invite"
            value={editguest.prenom_invite}
            onChange={handleInputChangeEdit}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email_invite"
            value={editguest.email_invite}
            onChange={handleInputChangeEdit}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Information supplémentaire"
            name="info_invite"
            value={editguest.info_invite}
            onChange={handleInputChangeEdit}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fonction"
            name="fonction_invite"
            value={editguest.fonction_invite}
            onChange={handleInputChangeEdit}
            fullWidth
            margin="normal"
          />
          <input
            type="file"
            name="image_invite"
            onChange={handleImageUpload}
            style={{ margin: "20px 0" }}
            accept="image/*"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Annuler
          </Button>
          <Button
            onClick={() => modifyGuest(selectedGuestId)}
            color="primary"
            variant="contained"
          >
            Modifier
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CaptationDetails;
