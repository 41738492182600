import React, { Component } from 'react';
import AVVuMeter from '../AVVuMeter';
import AVButton from '../AVButton';
import '../avstyle.scss';

class AVInputCombo extends Component {
  static defaultProps = {
    width:100,
    suspend_waiting:false,
    active_color:"green",
    suspend_waiting_color:"white",
    input_data: {
      active:false,
      meterF1:0.0,
      meterF2:0.0,
    }, 
    audio_input: "",
    input: "",
    function: "",
    value: "",
    label:"",
    width:60*16/9,
    height:60,
  }

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount(){
    this.setState({
        active:false
    });
  }

  
  onClick(e){
    if(this.props.suspend_waiting)
      this.props.onClick_suspend_waiting()
    else{
      this.props.onClick && this.props.onClick(e);
    }
  }

  render() {
    const { onClick, children } = this.props;
    const c = this.props;
    let state_class = this.props.active?"active":"";
    if(this.props.suspend_waiting){
      state_class = "active " + this.props.suspend_waiting_color; 
    }
    else if(this.props.active_color == "red")
      state_class += " red";
    else
      state_class += " green";
    
    return (
    <div style={{position:"relative", opacity:this.props.input_data.dummy?0.5:1}}>
      <div>
        <button
            {...this.props}
            onClick={(e)=>this.onClick(e)}
            className={"av largebtn " + state_class}
            style={{  opacity:this.props.dummy?0.5:1, }}
            >
            {this.props.label}
        </button></div>
      <AVVuMeter
          style={{position:"absolute"}} 
          left={130}
          height={59}
          top={12}
          F1={this.props.input_data.meterF1}
          F2={this.props.input_data.meterF2}
      />
    </div>
    );
  }
}

export default AVInputCombo; 