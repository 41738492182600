import React, { useEffect, useState } from "react";
import withRouter from "../lib/withRouter";
import Captation from '../components/Captation';
import { Box, Tabs, Tab } from "@mui/material";
import Guest from "../components/Guest";
import FactureForm from "../components/facture";
import CaptationRole from "../components/CaptationRole";
import OnshoPageContainer from "../page/OnshoPageContainer";
import Spx from "./Spx";
const token = localStorage.getItem('token');

const RecordingDetails = (props) => {
  const [captation, setCaptation] = useState(null);
  const [guests, setGuests] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('one');
  const [captationId, setCaptationId] = useState(null);

  const fetchSequences = async (captationId) => {
    setLoading(true);
    try {
      const response = await fetch(`/b/sequences-for-captation/${captationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des séquences");
      }
      const data = await response.json();
      setSequences(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    const response = await fetch('/b/get-users', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setUsers(data);
    } else {
      setError('Erreur lors de la récupération des utilisateurs.');
    }
  };

  const fetchCaptation = async (captationId) => {
    try {
      const response = await fetch(`/b/get-recording/${captationId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération de la captation");
      }
      const data = await response.json();
      setCaptation(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { captationId } = props.router.params;
    setCaptationId(captationId);
    fetchCaptation(captationId);
    fetchUsers();
    fetchSequences(captationId);
  }, [props.router.params.captationId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <OnshoPageContainer component="main">

    <div>
      <h1>MA CAPTATION</h1>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="one" label="ma captation" />
          <Tab value="two" label="mes guests" />
          <Tab value="three" label="ma facture" />
          <Tab value="four" label="Role" />
          <Tab value="five" label="Spx style" />

        </Tabs>
      </Box>
      {value === 'one' && captation && users && sequences && (
        <Captation captation={captation} users={users} sequences={sequences} />
      )}
      {value === 'two' && (
        <Guest captationId={captationId}/>
      )}
      {value === 'three' && (
        <FactureForm captationId={captationId} />
      )}
      {value === 'four' && (
        <CaptationRole captationId={captationId} />
      )}
      {value === 'five' && (

        <Spx captationId={captationId} />
    )}
    </div>
    </OnshoPageContainer>
  );
};

export default withRouter(RecordingDetails);
