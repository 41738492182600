import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './page/Home';
import reportWebVitals from './reportWebVitals';
import RegisterForm from './page/Register-Page';
import LoginForm from './page/Login-page'; // Correction ici, présume que LoginForm est dans './page/LoginForm'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VerificationPage from './page/Verifcation-page';
import Passwordoublie from './page/PasswordOublie';
import ResetPassword from './page/ReintialiserMDP';
import GestionUserPage from './page/Gestion-User-Page';
import Dashboard from './page/dashboard';
import Themetemplate from './themetemplate';
import MyRecordings from './page/MyRecordings';
import RecordingDetails from './page/RecordingDetails'
import GuestCreationPage from './page/GuestCreationPage';
import AdminPage from './page/AdminPage';
import Spx from './page/Spx';
import KitDashboard from './page/Kit-dashboard';
import PageTest from './page/PageTest';
import AVCtrlDirector from './page/AVCtrlDirector';
import AVCtrlGuests from './page/AVCtrlGuests';
import AVCtrlAudience from './page/AVCtrlAudience';
import AVCtrlIntercom from './page/AVCtrlIntercom';
import AVCtrlLayout from './page/AVCtrlLayout';

const root = ReactDOM.createRoot(document.getElementById('root'));
 
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/vmixctrl" element={<AVCtrlDirector/>}/>
        <Route path="/director/:captationId" element={<AVCtrlDirector/>}/>
        <Route path="/director" element={<AVCtrlDirector/>}/>
        <Route path="/guests" element={<AVCtrlGuests/>}/>
        <Route path="/audience" element={<AVCtrlAudience/>}/>
        <Route path="/operator" element={<AVCtrlAudience/>}/>
        <Route path="/intercom" element={<AVCtrlIntercom/>}/>
        <Route path="/layout" element={<AVCtrlLayout/>}/>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/home" element={<App />} />
        <Route path='/Verification' element={< VerificationPage />} />
        <Route path='/PasswordOublie' element={<Passwordoublie />} />
        <Route path="/reset-password-validation/:token" element={<ResetPassword />} />
        <Route path="/GestionUser" element={<GestionUserPage />} />
        <Route path="/dashboard" element={<Dashboard />} />my-recordings
        <Route path="/my-recordings" element={<MyRecordings />} />
        <Route path="/themetemplate" element={<Themetemplate />} />
        <Route path="/captationdetails/:captationId" element={<RecordingDetails/>} />
        <Route path="/Guests/:captationId" element={< GuestCreationPage/>} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/PageTest" element={<PageTest />} />
        <Route path="/spxshow" element={<Spx />} />
        <Route path='/kitdashboard' element={<KitDashboard />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
