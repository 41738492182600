
import React, { useState , useEffect ,Component} from 'react';
import { Link,  Navigate, redirect } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import MailIcon from '@mui/icons-material/Mail';


import OnshoPageContainer from './OnshoPageContainer';
import Sequence from '../components/Sequence';

import {
    Button,
    Box,
    Chip,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    Typography,
    Tab,
    Tabs,
  } from "@mui/material";

const token = localStorage.getItem('token');

class MyRecordings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordings: [],
      error: null,
      loading: null,
      newCaptation: { nomcaptation: '' },
      newcaptionadded: false,
    };
    this.addCaptation = this.addCaptation.bind(this);
    this.handleCaptationChange = this.handleCaptationChange.bind(this);
  }
  setError(errorMessage) {
    this.setState({ error: errorMessage });
  }

  async componentWillMount(){
    await this.fetch_recordings();
  }
  handleCaptationChange(event) {
    this.setState({
      newCaptation: {
        nomcaptation: event.target.value
      }
    });
  }
  async fetch_recordings(){
    this.setState({
      recordings:[],
      error: null,
      loading: null
    })
    try {
      const userInfo = localStorage.getItem('userInfo');
      if (!userInfo) {
        this.setState({error:'Utilisateur non trouvé.'});
      }
      else{
        const userId= localStorage.getItem('userId');

   /*      const response = await fetch(`/b/get-recording/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }); */
        const response = await fetch(`/b/get-captations`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          this.setState({recordings:data});
        } else {
          this.setState({error:'Erreur lors de la récupération des captations.'});
        }
      }
    } catch (error) {
        this.setState({error:'Erreur lors de la connexion au serveur.'});
    }
  }
  async addCaptation() {
    const userInfo = localStorage.getItem('userInfo');
    if (!userInfo) {
      this.setError('Utilisateur non trouvé.');
      return;
    }
    const userId = localStorage.getItem('userId');
    try {
      const response = await fetch(`/b/add-captation/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ nomcaptation: this.state.newCaptation.nomcaptation }),
      });
  
      if (response.ok) {
        const data = await response.json();
this.setState({newcaptionadded:true});
this.setState({newCaptation: { idcaptation: data.captationId }});
      } else {
        this.setError('Erreur lors de l\'ajout de la captation.');
      }
    } catch (error) {
      this.setError('Erreur lors de la connexion au serveur.');
    }
  }
  async removeCaptationByName (captationName) {
    const token = localStorage.getItem('token'); 
    if (!token) {
      this.setState({error:'Aucun token JWT trouvé.'});
      return;
    }
  
    const response = await fetch('/b/remove-captation-by-name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ captation_name: captationName }),
    });
  
    if (response.ok) {
      await this.fetch_recordings(); 
    } else {
      this.setState({error:'Erreur lors de la suppression de la captation.'});
    }
  };

  async addGuestToCaptation(captationId) {
  navigator.push('/addguesttocaptation', {captationId: captationId});
  }
  render() {
    const self = this;
    if (this.state.newcaptionadded) {
      return <Navigate to={`/captationdetails/${this.state.newCaptation.idcaptation}`} />;
    }
    if (this.state.loading) 
      return <div>Chargement...</div>;
    if (this.state.error) 
    return <div>Erreur : {this.state.error}</div>;
    else
      return  (
        <OnshoPageContainer component="main">
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <TextField
        label="Nom de la captation"
        value={this.state.newCaptation.nomcaptation}
        onChange={this.handleCaptationChange}
        margin="normal"
        fullWidth
      />
      <Button onClick={this.addCaptation} variant="contained" color="primary">
        Ajouter Captation
      </Button>
    </Paper>
          {this.state.recordings.map((captation, index) => (
            <Paper key={index} elevation={2} style={{ padding: '20px', margin: '10px 0' }}>
              <Typography variant="h6">{captation.nomcaptation} - {captation.typecaptation}</Typography>
              <Typography variant="body1">Date: {new Date(captation.datecaptation).toLocaleDateString()}</Typography>
              <Typography variant="body1">Lieu: {captation.locationcaptation}</Typography>
              <Typography variant="body1">Durée: {captation.duration}</Typography>
              <Typography variant="body1">Heure Live: {captation.heure_live}</Typography>
              <Typography variant="body1">Ouverture Portes: {captation.ouverture_portes_public}</Typography>
              <Typography variant="body1">Instructions d'accès: {captation.instruction_acces_digicode}</Typography>
              <Typography variant="body1">Horaires d'ouverture: {captation.instruction_acces_horaires_ouverture}</Typography>
              <Typography variant="body1">Informations utiles: {captation.autres_informations_utiles}</Typography>
              {/* Convertir les ObjectId en string pour l'affichage, si nécessaire */}
              <Typography variant="body1">Contact événement: {captation.contact_evenement_client}</Typography>
              <Typography variant="body1">Responsable Internet: {captation.contact_responsable_internet}</Typography>
              <Typography variant="body1">Enregistrement: {captation.record ? 'Oui' : 'Non'}</Typography>
              {/* Assurez-vous de convertir ou de traiter correctement les autres champs complexes comme les ObjectId, les tableaux, etc. */}
              <Link to={`/captationdetails/${captation._id}`}>Voir les détails</Link>

              <Button onClick={() => this.removeCaptationByName(captation.nomcaptation)} variant="contained" color="error">
                Supprimer
              </Button>
            </Paper>
          ))}
    
          {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
        </OnshoPageContainer>
      );
     
    

  }
}
  


/*  */
export default MyRecordings;
