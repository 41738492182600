import React from 'react';
import AVCtrlBase from './AVCtrlBase';

class AVCtrlIntercom extends React.Component {
  render() {
    return <AVCtrlBase
      destination_name="intercom"
    ></AVCtrlBase>
  }
}

export default AVCtrlIntercom;