import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function KitDashboard() {
    const [recordings, setRecordings] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const synchroAllCaptatiom = async () => {
        const responsestyle = await fetch('/spx/SynchroAll/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            }
        });
        if (responsestyle.ok) {
            console.log("Synchro all success");
        } else {
            throw new Error('Synchro all failed');
        }
    };
    const fetch_recordings = async () => {
        try {
            const response = await fetch('/spx/get-kit-captation', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (response.ok) {
                const jsonResponse = await response.json();
                if (jsonResponse.data && jsonResponse.data.length > 0) {
                    setRecordings(jsonResponse.data);
                } 
            
            }else {
                    const directoryResponse = await fetch('/staticfiles/data', {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                        }
                    });
                    const directoryJson = await directoryResponse.json();
                    if (directoryJson.directories) {
                        let localRecordings = [];
                        for (const dir of directoryJson.directories) {
                            const captationResponse = await fetch(`/staticfiles/data/${dir}/captation.json`, {
                                method: 'GET',
                                headers: {
                                    "Content-Type": "application/json",
                                }
                            });
                            if (captationResponse.ok) {
                                const captationData = await captationResponse.json();
                                localRecordings.push({
                                    ...captationData,
                                    _id: dir
                                });
                            }
                        }
                        setRecordings(localRecordings);
                    } else {
                        throw new Error('No directories found');
                    }
                }
            
        } catch (error) {
            setError(error.message);
        }
    };

    const handleRecordingClick = async (id) => {
        try {
            const response = await fetch(`/spx/get-kit-captation-style/${id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (response.ok) {
                console.log("Captation style saved successfully");
                navigate("/director/" + id);
            } else {
                throw new Error('Failed to save captation style');
            }
        } catch (error) {
            console.error("Error during click handling:", error);
        }
    };

    useEffect(() => {
        fetch_recordings();
    }, []);

    return (
        <div>
            <h1>Kit Dashboard</h1>
            {error ? (
                <p>{error}</p>
            ) : (
                recordings.map((recording) => (
                    <div key={recording._id} onClick={() => handleRecordingClick(recording._id)} style={{ cursor: 'pointer' }}>
                        <h2>{recording.nomcaptation}</h2>
                        <p>Location: {recording.locationcaptation}</p>
                        <p>Date: {new Date(recording.datecaptation).toLocaleDateString()}</p>
                        <p>Duration: {recording.duration} minutes</p>
                    </div>
                ))
            )}
            <button onClick={() => synchroAllCaptatiom()}>Synchro all</button>
        </div>
    );
}

export default KitDashboard;
